import React, { useState, useRef, useEffect } from 'react'
import { Flipper, Flipped } from "react-flip-toolkit"
import { motion } from "framer-motion"
import UslugaKrok from './usluga_krok'
import ButtonPaluch from '../buttons/button_goto'
import typo from '../../images/podstrony/uslugi/okladka_tekst@2x.png'
import branding_bg from '../../images/podstrony/uslugi/okladka_branding@2x.png'
import gwiazda from '../../images/ikony_tekst/gwiazda_prosta.svg'
import korzysci from '../../images/podstrony/uslugi/korzysci.svg'
import strzalka from '../../images/podstrony/uslugi/strzalka_wspolpraca.svg'
import maska from '../../images/ikony/maska_biala.svg'
import Slider from '../slider/slider'
import back from '../../images/ikony/goback.svg'
import TransitionLink from 'gatsby-plugin-transition-link'
import Seo from '../seo'
import okladka from '../../images/seo/branding.jpg'
import InneUslugi from './inne'
import Rekomendacje from '../rekomendacje'
import Formularz from '../formularz/formularz'

import paluch from '../../images/ikony/paluch.svg'

import tracker_poczatek from '../../images/nowy_tracker/poczatek@2x.png'
import tracker from '../../images/nowy_tracker/tracker@2x.png'


import krok1 from '../../images/podstrony/uslugi/branding/1.svg'
import krok2 from '../../images/podstrony/uslugi/branding/2.svg'
import krok3 from '../../images/podstrony/uslugi/branding/3.svg'
import krok4 from '../../images/podstrony/uslugi/branding/4.svg'
import krok5 from '../../images/podstrony/uslugi/branding/5.svg'
import krok6 from '../../images/podstrony/uslugi/branding/6.svg'


const UslugaBranding = ({title, entry}) => {    

    const [krokAktywny, ustawKrok] = useState();

    const kroki = useRef()
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const pas = useRef()

    var refs = [ref1, ref2, ref3, ref4]

    var pasCrop;


    useEffect(() => {

  
        
        
        return () => {

        }
    }, []);

    

    const list = ['Research', 'Strategia marki & komunikacji', 'Naming', 'Identyfikacja wizualna', 'Brand direction'];
    const item = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -100 },
    }
    const item2 = {
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: 100 },
    }


    function LinkPrzycisk(props){

        if(props.tekst) {
          return(
            <a href={props.link} className="bread-link">
       
                <img src={paluch} />
                <span className="przycisk">{props.tekst}</span>
           
            </a>
          )
        }
        else{ 
          return(<></>)
        }
            
        
    
         
        }



    const variants = {
        visible: i => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.3 + 0.6,
            },
        }),
        hidden: { opacity: 0, y: 50 },
    }


    useEffect(() => {

        
        console.log(Slider);

     
    }, [krokAktywny]);








    return (
        <>
        <Seo title="BRANDING" description="Projektujemy wyjątkowe marki. Analizujemy rynek, rozmawiamy z jego uczestnikami, tworzymy strategie marek i wspieramy Was we wdrożeniu ich na rynek." image={okladka} />
        <div className="usluga-okladka-wrapper">
                        <img className="usluga-okladka" src={typo} />
                        <img className="usluga-okladka" src={branding_bg} />
                        </div>
        <div className="podstrona-wrapper" id="usluga-info" onClick={() => (console.log({ krokAktywny }))}>

        

            <div className="usluga-wrapper usluga-aktywna">
                <div id="usluga-rzad" className="usluga-zaladowana">
                    <div className="usluga-rzad">
                        <TransitionLink 
                        state={{ title: 'USŁUGI' }}
                        activeClassName="podstrona-aktywna"
                        to="/uslugi"
                        partiallyActive={true}
                        exit={{
                          length: 1,
                          state: { x: -entry.state.len},
                        }}
                        entry={{
                          length: 1,             
                          state:  {x: (entry.state.len), len: entry.state.len},
                        }}
                        
                        
                        >
                            <img

                                src={back}
                                className="usluga-wroc"
                            />
                        </TransitionLink>

                        <div className="usluga-header-maska"></div>
                        <h1>{title}</h1>
                        <ul>

                            {list.map((l) => (
                                <p
                                    key={l}




                                >{l}</p>
                            ))}


                        </ul>
                        <div



                            className="usluga-przycisk" style={{
                                height: '100%'
                            }}>
                            <ButtonPaluch
                                text="ZOBACZ WIĘCEJ" />
                        </div>
                        <div className="usluga-linia-bread"></div>
                        <div className="bread-dodatkowe">
                        <LinkPrzycisk  link="/formularz" tekst="KONTAKT" />
                        <LinkPrzycisk  link="/projekty" tekst="PROJEKTY" />
                        </div>
                    </div>



                    <div 
                     className='usluga-opis-wrapper' >

                        
                        
                        <motion.div
                            
                            animate={{ y: 0 }}
                            transition={{ ease: "easeOut", duration: 2 }}
                            style={{   position: 'relative' }}
                            className={(!krokAktywny ? "usluga-wjazd" : "usluga-wjazd kroki-aktywne")}
                            >
                            <h6>Projektujemy wyjątkowe marki</h6>
      <h4>Projektujemy marki i dostosowujemy je do potrzeb klientów. Analizujemy rynek, rozmawiamy z jego uczestnikami, tworzymy strategie marek i wspieramy Was we wdrożeniu ich na rynek. Projektujemy doświadczenia. Pracujemy w modelu projektowym lub stajemy się częścią Twojego zespołu w formie stałej współpracy.</h4>
                            
                        
                            <div className="usluga-kroki">
                                <h6>Nasze kompetencje</h6>


                                <div>
                                    <div ref={ref1} className="krok-wrap" id="krok1">

                                        <UslugaKrok
                                        img={krok1}
                                        tytul="Research"
                                        podtytul="Zrozumienie dynamiki marki"
                                        tekst="Każdy projekt wymaga dobrego przygotowania merytorycznego. Wy dostarczycie nam część istotnych informacji o działalności firmy i środowisku projektu - to bardzo ważne! Resztę pozyskamy sami - posłuchamy co mają do powiedzenia Twoi klienci, przeprowadzimy analizę Twojej marki w mediach, zweryfikujemy działania konkurencji, przeprowadzimy audyt wizerunkowy i ustalimy postrzeganie Twojej marki. Dobór narzędzi zależy od celów projektu."
                                        
                                        krokId="1" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref2} className="krok-wrap" id="krok2">

                                        <UslugaKrok 
                                         img={krok2}
                                        tytul="Strategia marki"
                                        podtytul="Marka, która angażuje"
                                        tekst="Strategia marki to idea, która scala wszystkie działania wizerunkowe. To grupa charakterystyk i określonych zachowań marki zestawiona z precyzyjnie zdefiniowanymi grupami potencjalnych klientów. Na tym etapie tworzymy koncepcję, definiujemy najważniejsze elementy marki bazując na wiedzy o Twojej organizacji i motywacjach ludzi, których problemy możemy rozwiązać z wykorzystaniem Twoich usług i produktów." krokId="2" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref3} className="krok-wrap" id="krok3">

                                        <UslugaKrok 
                                         img={krok3}
                                        tytul="Strategia komunikacji"
                                        podtytul="Efektywna obecność w mediach"
                                        tekst="Strategia komunikacji określa długofalowo ton komunikacji i główne komunikaty, które marka będzie przekazywała jako swój fundament wizerunkowy. Mając zdefiniowaną markę i znając problemy oraz korzyści, których szukają potencjalni klienci, możemy stworzyć koncepcję komunikacjI, która na te problemy odpowie. Na tym etapie określimy także media, które będą najbardziej efektywne dla Twojego biznesu oraz zestaw zachowań i obszarów, zrealizują założone cele biznesowe." 
                                        krokId="3" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>
                                    <div ref={ref4} className="krok-wrap" id="krok4">

                                        <UslugaKrok
                                         img={krok4}
                                        tytul="Naming"
                                        podtytul="Brzmienie Twojej marki"
                                        tekst="Nazwy marek i produktów, to obok znaków, fundamenty tożsamości Twojego biznesu. Nazwa powinna odzwierciedlać jego charakter, być zgodna z trendami, osobowością marki i rezonować wobec kluczowych grup interesariuszy w Twoim ekosystemie. Tworzymy koncepty skrojone na miarę, w obszarach, których tego potrzebujesz." 
                                        krokId="4" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok5}
                                    tytul="Identyfikacja wizualna"
                                    podtytul="Inspirujące doświadczenie marki"
                                    tekst="Marki to doświadczenia a z interesującymi Cię doświadczeniami chcesz się identyﬁkować. Ułatwiamy to Twoim klientom tworząc systemy identyﬁkacji, które są wyjątkowe wizualnie i opracowane w taki sposób, by być maksymalnie spójnymi w wykorzystywanych przez markę środkach przekazu. Na tym etapie opiszemy podstawowe elementy identyifkacji Twojej ﬁrmy (znak, barwa, typograﬁa, etc.), i stworzymy szereg niezbędnych materiałów promocyjnych." 
                                    krokId="5" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                    <div ref={ref5} className="krok-wrap" id="krok4">

                                    <UslugaKrok
                                     img={krok6}
                                    tytul="Brand direction"
                                    podtytul="Codzienne wsparcie Twojej marki"
                                    tekst="Wspieramy Twoją markę w ramach codziennych działań w obszarach brandingu, projektowania i mediów. Tworzymy koncepcje działań wizerunkowych, projektujemy niezbędne materiały, czuwamy nad Twoim wizerunkiem w mediach. Współpracujemy na zasadzie ryczałtu. Każdy miesiąc rozpoczynamy spotkaniem, podczas którego wytyczamy nowe cele i kończymy raportem z wykonanych prac. Pracujesz z dedykowanym project managerem i dysponujesz zespołem, który na bieżąco odpowiada na Wszystkie Twoje potrzeby." 
                                    krokId="6" krokAktywny={krokAktywny} ustawKrok={ustawKrok} />
                                    </div>

                                </div>
                            </div>



                        </motion.div>

                        <div className="usluga-proces">
                            <h6>Proces</h6>
                            <div className="usluga-proces-tracker">
                                <img src={tracker_poczatek} />
                                <div className="tracker-pas">
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-punkt"></div>
                                    <div className="tracker-linia"></div>
                                    
                                </div>
                                <div className="tracker-wrapper">
                                <img className="tracker" src={tracker} />
                                </div>

                                
                                
                                
                            </div>

                            <div className="usluga-proces-kroki">
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">1. ANALIZA</span>
                                    <p>Realna wiedza o Twojej organizacji, klientach i konkurencji jest kluczowa dla realizowania jakościowych projektów brandingowych. W Odrze jesteśmy gotowi by dostać się do sedna sprawy, z wykorzystaniem profesjonalnych metod badawczych</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">2. KONCEPCJA</span>
                                    <p>Tworzymy dedykowane koncepcje oparte na danych pochodzących od Ciebie i z etapu badań. Nie powielamy schematów, bo wiemy jak ważna jest unikalność Twojego biznesu.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">3. WDROŻENIE</span>
                                    <p>Pomagamy Ci na etapie wdrożenia marki. Przygotowujemy kompleksowo materiały, wspieramy etap produkcji, realizujemy warsztaty z Twoim zespołem. Wszystko by Twoja marka powstawała zgodnie z planem.</p>      
                                </div>
                                <div className="usluga-proces-krok">
                                    <span className="przycisk">4. OBSŁUGA</span>
                                    <p>Po wdrożeniu koncepcji wciąż możesz liczyć na nasze wsparcie - doradzamy w kwestiach strategicznych, realizujemy zadania projektowe, wspieramy Cię w promocji Twojej marki zarówno online i offline. Poznaj usługi “design” i “media”, by dowiedzieć się więcej.</p>      
                                </div>
                            </div>

                        </div>

                        <div className="usluga-wspolpraca">
                         
                            <h6>Modele współpracy</h6>
                            <div>
                            <div className="box-szary">
                                <span className="przycisk">Model projektowy</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>Praca w modelu projektowym najlepiej sprawdza się przy jednorazowych realizacjach, które posiadają określony zestaw składowych. W tym modelu przygotowujemy wstępną wycenę przed rozpoczęciem działań.</p>
                                </div>
                                <div className="kolumna-info">
                                 
                                    <p>Choć taki sposób działania wydaje się wygodny, należy pamiętać o tym, że jest on mniej odporny na zmiany w czasie trwania projektu. Tym, którym zależy na rozwijaniu realizowanych koncepcji rekomendujemy pracę w modelu ryczałtowym.</p>
                                </div>
                                </div>
                            </div>
                            <div className="box-szary">
                                <span className="przycisk">Model ryczałtowy - abonament</span>
                                <div>
                                <div className="kolumna-info">
                                    <img src={korzysci} />
                                    <p>W modelu ryczałtowym współpracujemy głównie z klientami, których obsługujemy w sposób ciągły. Polega on na ustaleniu określonej miesięcznej kwoty, która przeliczona jest na ilość godzin i kompetencje wykorzystywane w projekcie.</p>
                                </div>
                                <div className="kolumna-info">
                                  
                                    <p>Dzięki posiadanemu abonamentowi zawsze jesteśmy gotowi na Twoje briefy a Twoje zlecenia wpadają na początek kolejki. Taka forma współpracy pozwala na znacznie większą elastyczność i zapewnia bezpieczeństwo Twojej komunikacji.</p>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        <Rekomendacje />
                        

                        
                        
                    </div>
                    <Slider />
                </div>
            </div>
            <h6 className="zobacz-inne-uslugi" >Zobacz inne usługi</h6>
            
        </div>
        <InneUslugi  usun="BRANDING" />
        <Formularz />
        </>
    )


}



export default UslugaBranding
