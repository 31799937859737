import React, { useState } from 'react'
import { Link } from 'gatsby'


import Bread from '../../components/bread'
import ButtonPaluch from '../../components/buttons/button_goto'
import { Flipper, Flipped } from 'react-flip-toolkit'

import SEO from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import style from '../uslugi.css'

import UslugaBranding from '../../components/uslugi/branding'


const title = "BRANDING"   


class Usluga extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
            
            <UslugaBranding title={title} entry={this.props.entry} />
            </>
        )
    }
}


export default Usluga
